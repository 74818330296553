import * as _nodeGypBuild2 from "node-gyp-build";

var _nodeGypBuild = "default" in _nodeGypBuild2 ? _nodeGypBuild2.default : _nodeGypBuild2;

import _fallback from "./fallback";
var exports = {};

try {
  exports = _nodeGypBuild(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname);
} catch (e) {
  exports = _fallback;
}

export default exports;
export const mask = exports.mask,
      unmask = exports.unmask;